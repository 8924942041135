<template>
  <a-card :bordered="false">
    <a-page-header
      v-if="queryParam.id"
      style="border: 1px solid rgb(235, 237, 240); margin-bottom: 35px"
      title="抄表记录"
      @back="() => $router.go(-1)">
    </a-page-header>
    <s-table
      ref="table"
      size="small"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="count_render" slot-scope="text" style="text-align: right; min-width: 100px; max-width: 110px">
        {{ text }}
      </div>
      <div slot="money_render" slot-scope="text" style="text-align: right; min-width: 100px; max-width: 110px">
        {{ (text / 100).toFixed(2) | numberFormat }}
      </div>
      <span slot="color" slot-scope="color">
        <a-tag
          style="height: 15px"
          :color="color"
        >
          {{ '    ' }}
        </a-tag>
      </span>
      <span slot="action" slot-scope="text, record" v-action:delete>
        <template>
          <a-popconfirm
            v-if="record.water_bill_id === -1"
            title="确定要删除么？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleDelet(record)"
          >
            <a>删除</a>
          </a-popconfirm>
        </template>
      </span>
    </s-table>
  </a-card>
</template>

<script>
import moment from 'moment'
import { STable } from '@/components'
import { water_meter_record_create, water_meter_record_delete, water_meter_record_list } from '@/api/water_meter'
import { Dictionaries } from '@/common/AllConstant'
import CreateForm from '../modules/RecordCreateForm'
import { water_bill_create } from '@/api/water'

export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm
  },
  data () {
    return {
      rules: {
        batch: [
          { required: true, message: '请输入', trigger: 'blur' }
        ]
      },
      form: {
        batch: ''
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      billLoading: false,
      billvisble: false,
      loading: false,
      confirmLoading: false,
      dictionaries: Dictionaries,
      // 创建窗口控制
      visible: false,
      mdl: {},
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: 50,
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '水表编号',
          dataIndex: 'water_meter_number',
          ellipsis: true
        },
        {
          title: '上期表读数',
          dataIndex: 'last_value',
          scopedSlots: { customRender: 'count_render' },
          align: 'center'
        },
        {
          title: '表读数',
          dataIndex: 'value',
          scopedSlots: { customRender: 'count_render' },
          align: 'center'
        },
        {
          title: '单价',
          dataIndex: 'price',
          scopedSlots: { customRender: 'money_render' },
          align: 'center'
        },
        {
          title: '总价',
          dataIndex: 'amount',
          scopedSlots: { customRender: 'money_render' },
          align: 'center'
        },
        {
          title: '关联状态',
          dataIndex: 'water_bill_id',
          customRender: (text) => text > 0 ? ' 已关联' : '未关联'
        },
        {
          title: '批次',
          dataIndex: 'batch',
          ellipsis: true
        }
        // ,
        // {
        //   title: '操作',
        //   dataIndex: 'action',
        //   scopedSlots: { customRender: 'action' }
        // }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        console.log('loadData.parameter', parameter)
        return water_meter_record_list({ bill_id: this.$route.params.id })
          .then(res => {
            console.log(res.data, '我是数据列表@')
            return res.data
          })
      },
      selectedRowKeys: [],
      selectedRows: [],

      // custom table alert & rowSelection
      options: {
        alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false
    }
  },
  created () {
    this.tableOption()
    console.log(this.$Dictionaries)
    const id = this.$route.params.id
    if (id) {
      this.queryParam.id = id
    }
  },
  methods: {
    // 按照时间创建租金账单
    handleWaterOk () {
      if (!this.form.batch) {
        this.$message.error('表单不能为空')
        return
      }
      const param = Object.assign({}, { batch: this.form.batch })
      water_bill_create(param).then((res) => {
        console.log(res)
        if (res.success) {
            this.$message.success('本次生成' + res.data + '条账单')
        }
        this.billvisble = false
        this.billLoading = false
      })
    },
    handleBillCancel () {
      this.billvisble = false
      this.billLoading = false
    },
    water_bill_c () {
      this.billvisble = true
    },
    tableOption () {
      if (!this.optionAlertShow) {
        this.options = {
          alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange
          }
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null
        }
        this.optionAlertShow = false
      }
    },
    handleAdd () {
      this.mdl = null
      this.visible = true
    },
    handleAddCancel () {
      this.visible = false

      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleEdit (record) {
      this.$emit('onEdit', record)
    },
    handleDelet (record) {
      water_meter_record_delete(record.id).then((res) => {
        console.log(res)
        console.log('删除成功------')
        this.$refs.table.refresh()
      })
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
          var res = values.data
          res = res.replace('，', ',')
          res = res.split('\n')
          res = res.map((item) => {
            return item.split(',')
          })
          values.data = res
          console.log(res, '我是切分的数组')
          // 新增
          water_meter_record_create(values).then(res => {
              this.visible = false
              this.confirmLoading = false
              // 重置表单数据
              form.resetFields()
              // 刷新表格
              this.$refs.table.refresh()

            console.log('删除成功------')
            })
        } else {
          this.confirmLoading = false
        }
      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },

    resetSearchForm () {
      this.queryParam = {
        date: moment(new Date())
      }
    }
  }
}
</script>
